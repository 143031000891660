import React from 'react'
import { Row, Col } from 'reactstrap';
import TimeCountDown from '../countdown'
import hImg3 from '../../images/slider/s1_2.jpg'
import hImg4 from '../../images/slider/shape3.png'
import hImg5 from '../../images/slider/shape4.png'
import love from '../../images/love.png'
import tree from '../../images/contact/1.png'
import handToHand from '../../images/image_photo_statis/IMG_2908.jpg'
import anotherImageCover from '../../images/image_photo_statis/IMG_2998-Edit.jpg'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Hero = (props) => {
    return(
        <section className="">
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    style={{textAlign: 'center'}}>
                    <div className="static-hero-left">
                        <div className="static-hero-shape-1"><img src={tree} alt="tree"/></div>
                        {/* <div className="static-hero-shape-2"><img src={love} alt="love"/></div> */}
                        <div className="" style={{margin: '20px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', padding: '20px', borderRadius: '10px', backgroundColor: 'rgba(0, 0, 0, 0.65)'}}>
                            <div className="static-hero-shape-3"><img src={handToHand} alt="handToHand"/></div>
                            <div data-swiper-parallax="300" className="slide-title">
                                {/* <h2 style={{marginBottom: '0px',  marginTop: '0px', fontFamily: 'Sacramento', fontWeight: 'normal', textShadow: '1px 1px 2px grey, 0 0 25px gold, 0 0 5px lightgrey', fontWeight: 'bold'}}> */}
                                <h2 style={{marginBottom: '0px',  marginTop: '0px', fontFamily: 'photograph', fontWeight: 'bold'}}>
                                    <span>K</span>unti&nbsp;
                                    <span>D</span>esy&nbsp;
                                    <span>W</span>ulandari
                                </h2>
                                <h2 style={{marginBottom: '0px',  marginTop: '0px', fontSize: '1.5rem', textShadow: '1px 1px 2px grey, 0 0 25px white, 0 0 5px lightgrey', fontWeight: 'normal'}}>&</h2>
                                {/* <h2 style={{marginBottom: '0px',  marginTop: '0px', fontFamily: 'Sacramento', fontWeight: 'normal', textShadow: '1px 1px 2px grey, 0 0 25px gold, 0 0 5px lightgrey', fontWeight: 'bold'}}> */}
                                <h2 style={{marginBottom: '0px',  marginTop: '0px', fontFamily: 'photograph', fontWeight: 'bold'}}>
                                    <span>M</span>och&nbsp;
                                    <span>G</span>alih&nbsp;
                                    <span>I</span>ndraprahastha
                                </h2>
                            </div>
                            <div data-swiper-parallax="400" className="slide-text">
                                <p style={{fontWeight: 'bold', fontFamily: 'Gilda Display', color: 'white'}}>21 Januari 2023</p>
                            </div>
                            <div className="wpo-wedding-date">
                                <div className="clock-grids">
                                    <TimeCountDown/>
                                </div>
                            </div>
                            <div className="clearfix">
                            </div>
                        </div>
                    </div>
                        
                </Col>

                <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    style={{textAlign: 'center'}}>
                    <AnimationOnScroll animateIn="animate__tada">
                        <div className="static-hero-right">
                            <div className="static-hero-img">
                                <img src={hImg3} alt="hImg3"/>
                                <div className="static-hero-shape-1"><img src={hImg4} alt="hImg4"/></div>
                                <div className="static-hero-shape-2"><img src={hImg5} alt="hImg5"/></div>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </Col>

            </Row>
            {/* <img src={anotherImageCover} alt="anotherImageCover"/> */}
        </section>
    )
}

export default Hero;