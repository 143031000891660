/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState} from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Hero from '../../components/hero'
import CoupleSection2 from '../../components/CoupleSection2';
import PortfolioSection2 from '../../components/PortfolioSection2';
import EventSection from '../../components/EventSection';
import SectionTitle from '../../components/SectionTitle'
import RSVP from '../../components/RSVP';
import Scrollbar from '../../components/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer';
import { Table, InputGroup, InputGroupText, Input } from 'reactstrap';
import hImg3 from '../../images/slider/shape3.png'
import handSanitizer from '../../images/health-protocol/hand-sanitizer.png'
import hands from '../../images/health-protocol/hands.png'
import mask from '../../images/health-protocol/mask.png'
import socialDistancing from '../../images/health-protocol/social-distancing.png'
import { connect } from "react-redux";
import {
    getDataUserOpenInvitation,
    getAllDataUser,
} from "../../store/actions/action";
import { useEffect } from "react";
import Sound from 'react-sound';
import music from "../../sound/tulus_teman_hidup.mp3"

const ListUser =(props) => {
    const push = useNavigate()
    useEffect(() => {
        if (counting === 0) {
            props.getAllDataUser()
            setCounting(1);
        } else {}
    }, [props]);
    const [counting, setCounting] = useState(0);
    const [textFind, setTextFind] = useState('');

    const funcGenerateHeader = (value) => {
        return <th key={value}><p 
            style={{
                lineHeight: 'normal', 
                color: 'white', 
                fontFamily: "Gilda Display", 
                fontSize: '12px', 
                padding: '0px',
                margin: '0px',
                fontWeight: '900'}}>
            {value}
        </p></th>
    }

    const funcGenerateContent = (value, index) => {
        return <td key={index}><p 
            style={{
                minHeight: '30px',
                lineHeight: 'normal', 
                fontWeight: 'normal', 
                // color: 'grey', 
                fontFamily: "Gilda Display", 
                fontSize: '12px', 
                padding: '0px',
                margin: '0px',
                color: `${value === 'Pagi' ? 'darkgrey' : value === 'Siang' ? 'gold' : 'grey'}`}}>
            {value}
        </p></td>
    }

    return(
        <Fragment>
            <InputGroup>
                <Input placeholder="Find Someone" style={{fontSize: '12px'}} onChange={(e) => {setTextFind(e.target.value)}}/>
                <InputGroupText>
                <p 
                    style={{
                        lineHeight: 'normal', 
                        fontWeight: '900', 
                        // color: 'grey', 
                        fontFamily: "Gilda Display", 
                        fontSize: '12px', 
                        padding: '0px',
                        margin: '0px',
                        color: `darkred`}}>
                    Find
                </p>
                </InputGroupText>
            </InputGroup>
            {/* {JSON.stringify(props.dataAllComments.data.data[0])} */}
            <Table responsive>
                <thead>
                    <tr style={{backgroundColor: 'red', textAlign: 'center'}}>
                        {[
                            funcGenerateHeader('No'),
                            funcGenerateHeader('Nama'),
                            funcGenerateHeader('Waktu'),
                            funcGenerateHeader('Tindakan'),
                        ]}
                    </tr>
                </thead>
                <tbody>
                    {
                        // eslint-disable-next-line array-callback-return
                        props.dataAllComments?.data?.data[0].map((dataUser, index) => {
                            if (dataUser.name.toLowerCase().search(textFind.toLowerCase()) !== -1) {
                                return <tr key={index}>
                                    {[
                                        funcGenerateContent(index + 1, index+'no'),
                                        funcGenerateContent(dataUser.name, index+'name'),
                                        funcGenerateContent(dataUser.time_of_invitation === 0 ? 'Pagi' : 'Siang', index+'time'),
                                    ]}
                                    <td key={index+'action'} style={{width: '30%'}}>
                                        <p 
                                            style={{
                                                lineHeight: 'normal', 
                                                fontWeight: 'normal', 
                                                color: 'grey', 
                                                fontFamily: "Gilda Display", 
                                                fontSize: '12px', 
                                                padding: '0px',
                                                margin: '0px'}}
                                            >
                                                <a href={`whatsapp://send?text=Assalamu%27alaikum%20Wr.%20Wb%0ABismillahirahmanirrahim.%0A%0AYth.%20${dataUser.name}%0A%0ATanpa%20mengurangi%20rasa%20hormat%2C%20perkenankan%20kami%20mengundang%20Bapak%2FIbu%2C%20Saudara%2Fi%20dan%20Sahabat%20untuk%20menghadiri%20acara%20pernikahan%20kami.%0A%0ABerikut%20link%20undangan%20kami%20untuk%20info%20lengkap%20nya%20%3A%0A%0Ahttps://teman-hidup.com/${dataUser.urlpath}%0A%0AMerupakan%20suatu%20kebahagiaan%20bagi%20kami%20apabila%20Bapak%2FIbu%2C%20Saudara%2Fi%20dan%20Sahabat%20berkenan%20untuk%20hadir%20dan%20memberikan%20doa%20restu.%0A%0ADan%20karena%20suasana%20masih%20pandemi%2C%20diharapkan%20untuk%20tetap%20menggunakan%20masker%20dan%20datang%20pada%20jam%20yang%20telah%20ditentukan.%0A%0ATerima%20kasih%20banyak%20atas%20perhatiannya.%0A%0AWassalamu%27alaikum%20Wr.%20Wb.`}>
                                                    Share with WA
                                                </a>
                                        </p>
                                    </td>
                                </tr>
                            }
                            
                        })
                    }
                    
                </tbody>
            </Table>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        dataAllComments: state.wishList.dataAllComments
    };
};

export default connect(mapStateToProps, {
    getAllDataUser,
})(ListUser);