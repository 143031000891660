import React, {Fragment} from 'react';
// import Navbar2 from '../../components/Navbar2';
import Hero5 from '../../components/hero5';



const HomePage6 =() => {

    return(
        <Fragment>
            {/* <Navbar2/> */}
            <Hero5/>
        </Fragment>
    )
};

export default HomePage6;