import React from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap';

const ContentImageType = (props) => {

  return (
    <div>
      {
        props.data.table_blog_content_content.length > 1 ?
        <Row>
          {
            props.data.table_blog_content_content.map((dataImage, indexImage) => {
              return <Col
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style={{textAlign: dataImage.align, paddingBottom: '20px'}}
              key={indexImage}>
                <img 
                  src={dataImage.content_image_base64} 
                  alt="" 
                  width={Number(dataImage.weight)}
                  height={Number(dataImage.height)}
                  style={{ objectFit: 'cover' }}
                />
              </Col>
            })
          }
        </Row> :
        <div
          style={{ 
            width: '100%',
            textAlign: props.data.content[0].align 
          }}>
          <img 
            src={props.data.table_blog_content_content[0].photo_link} 
            alt="" 
            width={Number(props.data.table_blog_content_content[0].weight)}
            height={Number(props.data.table_blog_content_content[0].height)}
            style={{ objectFit: 'cover' }}
            />
        </div>
      }
    </div>  
  )
}

export default ContentImageType;