import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../images/partners/1.png' 
import pimg2 from '../../images/partners/2.png' 
import pimg3 from '../../images/partners/3.jpeg' 
import pimg4 from '../../images/partners/4.png' 
import pimg5 from '../../images/partners/5.png' 
import pimg6 from '../../images/partners/6.jpeg' 
import pimg7 from '../../images/partners/7.png'
import pimg8 from '../../images/partners/8.jpeg'

const partners = [
    {
      pImg:pimg1,
      name: 'Dewangga Catering'
    },
    {
      pImg:pimg2,
      name: 'Mecapan Space and Photography'
    },
    {
      pImg:pimg3,
      name: 'Chandra MUA'
    },
    {
      pImg:pimg6,
      name: 'Askar Photography'
    },
    {
      pImg:pimg7,
      name: 'Rockologist'
    },
    {
      pImg:pimg8,
      name: 'Byrequestcraft'
    },
]


class PartnerSection extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (

            <section className={`wpo-partners-section ${this.props.tNone}`} style={{paddingBottom: '30px', paddingTop: '30px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="partner-grids partners-slider owl-carousel">
                                <Slider {...settings}>
                                    {partners.map((partner, pitem) => (
                                        <div className="grid" key={pitem}>
                                            <img src={partner.pImg} alt="" style={partner.name != "Mecapan Space and Photography" ? {borderRadius: '100px'} : {borderRadius: '100px'}}/>
                                            <p style={{
                                                fontFamily: 'Gilda Display', 
                                                fontWeight: '400 !important', 
                                                color: 'grey', 
                                                fontSize: '12px', 
                                                padding: '0px', 
                                                margin: '0px', 
                                                marginBottom: '3px'}}>{partner.name}</p>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PartnerSection;