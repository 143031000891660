/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
// import LocationMap from '../Modal'
import { Button } from '@material-ui/core'
import {useNavigate} from "react-router-dom";
import coupleImg1 from '../../images/couple/2.jpg'
import flowerTiny from '../../images/slider/slide-10-flower-tiny.jpg'
import { connect } from "react-redux";
import {
    setDataPathUserOpenInvitation,
} from "../../store/actions/action";


const Hero5 = (props) => {
    const push = useNavigate()
    useEffect(() => {
        var locationSplit = window.location.pathname.split(/[/-\s]/);
        var arrValueSplit = []
        for (let i = 0; i < locationSplit.length; i++) {
            if (locationSplit[i] !== '') {
                arrValueSplit.push(locationSplit[i])
            }
        }
        setPath(arrValueSplit.join('-'))
        setName(arrValueSplit.join(' '))
        props.setDataPathUserOpenInvitation(arrValueSplit.join('-'))
    }, [props]);
    const [path, setPath] = useState('');
    const [name, setName] = useState('');

    return(
        <section className="static-hero-s3" style={{height: '100vh', position: 'relative'}}>
            <div style={{height: '100vh', width: '100%', position: 'absolute', backgroundImage: `url(${flowerTiny})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right', opacity: '50%'}}></div>
            <div className="wpo-event-item">
                <div className="wpo-event-text">
                    <img src={coupleImg1} alt="" style={{border: '3px solid rgb(15 69 0 / 16%)', borderRadius: '50%', height: '130px', margin: '10px'}}/>
                    <h2>Kunti Desy<br/>&<br/>Galih Indra</h2>
                    <p style={{lineHeight: 'normal'}}>Kepada Bapak/Ibu/Saudara/i</p>
                    <p style={{fontSize: '15px', fontWeight: 'bold'}}>{name.toUpperCase()}</p>
                    <p style={{lineHeight: 'normal'}}>Tanpa Mengurangi Rasa Hormat Kami Mengundang Anda Untuk Hadir Di Acara Kami.</p>
                    <ul>
                        <li>
                            <Button
                                style={{border: 'none'}}
                                className={`btn heartbeat`}
                                onClick={() => {
                                    // this.props.history.push({pathname: '/digital-invitation'});
                                    push('/digital-invitation');
                                }}>
                                Lihat Detail Undangan...
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

// export default Hero5;
const mapStateToProps = (state) => {
    return {
    //   dataUserOpenInvitation: state.wishList.dataUserOpenInvitation,
    };
};

export default connect(mapStateToProps, {
    setDataPathUserOpenInvitation,
})(Hero5);