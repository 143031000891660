/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/BlogList'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { useParams, useNavigate } from "react-router-dom";
import { getAllDataBlog } from "../../store/actions/action";
import { connect } from "react-redux";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const BlogPage =(props) => {
    const { id } = useParams()
    const classes = useStyles();
    const push = useNavigate()

    const [allDataBlog, setAllDataBlog] = useState([]);
    const [count, setCount] = useState(0);
    const [paginationCount, setPaginationCount] = useState(1);
    const [mapArray, setMapArray] = useState([1, 2, 3, 4, 5]);
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        console.log('Masuk Page')
        console.log(count)
        console.log(id)
        if (count === 0 && id) {
            setCount(1)
            funSetPaginationCount(id)
        }
        if (JSON.stringify(allDataBlog) !== JSON.stringify(props.dataGetBlog) ) {
            if (props.dataGetBlog.data.data.length < 5) {
                var newMapArray = []
                for (let i = 0; i < mapArray.length; i++) {
                    if (mapArray[i] <= paginationCount) {
                        newMapArray.push(mapArray[i])
                    }
                }
                setMapArray(newMapArray)
            }
            setAllDataBlog(props.dataGetBlog.data.data)
            setOpen(false)
        }
    }, [props.dataGetBlog, id])

    const funSetPaginationCount = async (value) => {
        console.log('Masuk funSetPaginationCount')
        value = Number(value)
        await setPaginationCount(value)
        var arrCount = []

        if (value > 4) {
            for (let i = (value-1); i < (value-1)+5; i++) {
                arrCount.push(i)
            }
        } else {
            for (let i = 0; i < 5; i++) {
                arrCount.push(i+1)
            }
        }
        setMapArray(arrCount)

        let offset = (value-1) * 5
        
        props.getAllDataBlog(offset)
    }

    const choosePagination = (value) => {
        setOpen(true)
        setTimeout(() => {
            push('/blog/'+value)
            setCount(0)
        }, 1000);
    }
    
    return(
        <Fragment>
            <Backdrop className={classes.backdrop} open={open} onClick={() => {}}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <Navbar/>
            <PageTitle 
                pageTitle={'Kisah Cerita'} 
                pagesub={'Blog'}/> 
            <div className='bg-image-blog'>
                <BlogList 
                    allDataBlog={allDataBlog} 
                    paginationCount={paginationCount} 
                    funSetPaginationCount={(value) => choosePagination(value)}
                    mapArray={mapArray}/>
            </div>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        dataGetBlog: state.wishList.dataGetBlog
    };
};

export default connect(mapStateToProps, {
    getAllDataBlog,
})(BlogPage);
