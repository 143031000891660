import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Scrollbar = (props) => {

    return(
        <div>
            <div className="col-lg-12">
                <div className="header-menu">
                    {
                        props.statusUnhide ? 
                        <ul className="smothscroll" style={{bottom: '75px'}}>
                            <li style={{marginBottom: '10px'}}>
                                    <AnchorLink href='#scrool'>
                                        <i className="ti-arrow-up"></i>
                                    </AnchorLink>
                            </li>
                            <li style={{}} 
                                onClick={() => props.changeStatusPause()}>
                                {/* <AnchorLink className="" style={{ paddingTop: '1px' }}>
                                    <i className={props.statusPause ? "ti-control-pause" : "ti-control-play"}></i>
                                </AnchorLink> */}
                                <div 
                                    className="" 
                                    style={{ 
                                        paddingTop: '2px',
                                        backgroundColor: '#a3888c',
                                        color: '#fff',
                                        width: '30px',
                                        height: '30px',
                                        lineHeight: '25px',
                                        borderRadius: '50%',
                                        display: 'block',
                                        textAlign: 'center',
                                        border: '2px solid #a3888c',
                                    }}>
                                    <i className={props.statusPause ? "ti-control-pause" : "ti-control-play"}></i>
                                </div>
                            </li>
                        </ul> : <div />
                    }
                </div>
            </div>
        </div>
        
    )
}

export default Scrollbar;
