import React, {Fragment, useEffect, useState} from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom'
import blogs from '../../api/blogs'
import BlogSingle from '../../components/BlogDetails'
import Footer from '../../components/footer';
import {
    getOneDataBlog,
} from "../../store/actions/action";
import { connect } from "react-redux";


const BlogDetails =(props) => {
    const [count, setCount] = useState(0);
    const [blogDetails, setBlogDetails] = useState(0);

    const { id } = useParams()
    useEffect(() => {
        if (count === 0) {
            setCount(1)
            props.getOneDataBlog(id)
        }
        if (JSON.stringify(blogDetails) !== props.dataGetOneBlog) {
            console.log(props.dataGetOneBlog)
            setBlogDetails(props.dataGetOneBlog)
        }
    }, [props.dataGetOneBlog])

    // const BlogDetails = blogs.find(item => item.id === id)

    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={blogDetails?.table_blog_title} pagesub={'Blog'}/>
            <BlogSingle blogDetails={blogDetails}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        dataGetOneBlog: state.wishList.dataGetOneBlog,
        dataErrorGetOneBlog: state.wishList.dataErrorGetOneBlog
    };
};

export default connect(mapStateToProps, {
    getOneDataBlog,
})(BlogDetails);
// export default BlogDetails;
