/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
// import {Link} from 'react-router-dom'
// import Services from '../../api/service';
// import Projects from '../../api/projects';
import about from '../../images/blog/about-widget.jpg'
// import blogs from '../../api/blogs'
import {useNavigate} from "react-router-dom";
import Button from "@material-ui/core/Button";

const BlogSidebar = (props) => {

    const push = useNavigate()
    useEffect(() => {
        var articleDraft = localStorage.getItem('article_draft')
        if (articleDraft) {
            setListDraft(JSON.parse(articleDraft))
        }
    }, [])
    const [listDraft, setListDraft] = useState([]);

    // const SubmitHandler = (e) =>{
    //    e.preventDefault()
    // }

    const ClickHandler = () =>{
        // window.scrollTo(10, 0);
        push('/post-history/naraya_vidya_prahastha');
    }

    const generateDate = (value) => {
        let parseDate = new Date(value);
        return `${parseDate.getDate()}/${parseDate.getMonth()+1}/${parseDate.getFullYear()} ${parseDate.getHours()}:${parseDate.getMinutes()}`
    }

    const goToPostPage = (value) => {
        push(`/post-history/${value}/naraya_vidya_prahastha`);
    }

    const deleteDraft = async (index) => {
        var articleDraft = await localStorage.getItem('article_draft')
        articleDraft = JSON.parse(articleDraft)
        if (articleDraft.length > 1) {
            await articleDraft.splice(1, 1)
            await localStorage.setItem('article_draft', JSON.stringify(articleDraft))
            articleDraft = await localStorage.getItem('article_draft')
            if (articleDraft) {
                setListDraft(JSON.parse(articleDraft))
            }
        } else {
            await localStorage.removeItem('article_draft')
            articleDraft = await localStorage.getItem('article_draft')
            if (articleDraft) {
                setListDraft(JSON.parse(articleDraft))
            } else {
                setListDraft([])
            }
        }
    }


    return(
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar" style={{ maxWidth: 'none' }}>
                <div className="widget about-widget">
                    <div className="img-holder">
                        <img src={about} alt=""/>
                    </div>
                    {/* Dinamic --- */}
                    <h4 style={{ color: 'white' }}>Naraya Vidya Prahastha</h4>
                    <p style={{ color: 'white' }}>Halo Anak Cantik-nya Abba dan Amih...</p>
                </div>
                <div className="wpo-contact-widget widget">
                    <h4 style={{ color: 'white' }}>List Draft</h4>
                    {
                        listDraft.map((dataDraft, index) => {
                            return <div key={index} style={{ border: 'solid 1px grey', borderRadius: '10px', padding: '10px', marginBottom: '5px', cursor: 'pointer' }}>
                                <div onClick={() => goToPostPage(dataDraft.id)}>
                                    <p style={{ fontSize: '1rem', marginBottom: '0px' }}>{dataDraft.postTitle}</p>
                                    {
                                        dataDraft.content[0].class === 'format-standart-content' || dataDraft.content[0].class === 'format-quote' ?
                                        <p style={{ fontSize: '.7rem', marginBottom: '0px', fontWeight: 'bold', color: 'lightgrey' }}>{JSON.stringify(dataDraft.content[0].content)}</p> :
                                        <img src={dataDraft.content[0].content[0].base64} alt="..." style={{}} />
                                    }
                                    <p style={{ fontSize: '.7rem', marginBottom: '0px', fontWeight: 'bold', color: 'yellow' }}>{generateDate(dataDraft.last_date)}</p>
                                </div>
                                <Button className="collapseBtn" style={{ background: '#F80025', color: 'white', marginBottom: '10px', marginTop: '10px' }} fullWidth onClick={() => deleteDraft(index)}>
                                    Hapus Draft
                                </Button>
                            </div>
                        })
                    }
                    {/* {JSON.stringify(listDraft)} */}
                </div>
                {/* <div className="wpo-contact-widget widget"> */}
                <div className="wpo-contact-widget widget">
                    <h2>Menambah Cerita</h2>
                    <p>Untuk menambahkan cerita silahkan klik tombol dibawah</p>
                    <a onClick={ClickHandler} style={{ cursor: 'pointer' }}>Tambah Cerita</a>
                </div>
            </div>
        </div>
    )
        
}

export default BlogSidebar;
