import React from 'react';
import { Link } from 'react-router-dom'

const ContentQuoteType = (props) => {

  return (
    <div>
      <blockquote>{props.text}</blockquote>
    </div>  
  )
}

export default ContentQuoteType;