/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState} from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Hero from '../../components/hero'
import CoupleSection2 from '../../components/CoupleSection2';
import PortfolioSection2 from '../../components/PortfolioSection2';
import EventSection from '../../components/EventSection';
import SectionTitle from '../../components/SectionTitle'
import RSVP from '../../components/RSVP';
import Scrollbar from '../../components/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer';
import { Row, Col } from 'reactstrap';
import hImg3 from '../../images/slider/shape3.png'
import handSanitizer from '../../images/health-protocol/hand-sanitizer.png'
import hands from '../../images/health-protocol/hands.png'
import mask from '../../images/health-protocol/mask.png'
import socialDistancing from '../../images/health-protocol/social-distancing.png'
import { connect } from "react-redux";
import {
    getDataUserOpenInvitation,
    getAllDataUserComment,
    postDataCopyDonasi,
} from "../../store/actions/action";
import { useEffect } from "react";
import Sound from 'react-sound';
import music from "../../sound/tulus_teman_hidup.mp3"

import iconHomeCouple from '../../images/icon/icon_home_couple.png'
import iconCouple from '../../images/icon/icon_couple.png'
import iconGallery from '../../images/icon/icon_gallery.png'
import iconSchedule from '../../images/icon/icon_schedule.png'
import iconComment from '../../images/icon/icon_comment.png'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Navbar from '../../components/Navbar'
import './index.css'

const HomePage =(props) => {
    const push = useNavigate()
    useEffect(() => {
        if (counting === 0) {
            // setStatusPause(false)
            if (props.dataUserPath?.data) {
                props.getDataUserOpenInvitation(props.dataUserPath.data)
                props.getAllDataUserComment()
            } else {
                push('/404')
            }
            setCounting(1);
        } else {
            // setDataPutComment(props.dataPutComment)
            if (props.dataPutComment != null && JSON.stringify(props.dataPutComment) !== dataPutComment) {
                toast.success('Get Data Commets Success');
                setDataPutComment(JSON.stringify(props.dataPutComment))
                props.getAllDataUserComment()
            }
            var checkDataComment = props.dataAllComments && props.dataAllComments.data && props.dataAllComments.data.data
            var checkDataUser = props.dataUserOpenInvitation && props.dataUserOpenInvitation.data && props.dataAllComments.data.data
            setDataComment(JSON.stringify(props.dataAllComments?.data) !== '{}' ? checkDataComment ? props.dataAllComments?.data.data[0] : {} : {})
            setDataUser(JSON.stringify(props.dataUserOpenInvitation?.data) !== '{}' ? checkDataUser ? props.dataUserOpenInvitation?.data.data[0][0] : {} : {})
            if (JSON.stringify(props.dataUserOpenInvitation?.data) === '{}') {
                push('/404')
            }
        }

    }, [props]);
    const [counting, setCounting] = useState(0);
    const [dataUser, setDataUser] = useState({});
    const [dataComment, setDataComment] = useState({});
    const [dataPutComment, setDataPutComment] = useState({});
    const [statusPause, setStatusPause] = useState(true);

    return(
        <Fragment>
            <Navbar/>
            <Sound
                url={music}
                playStatus={statusPause ? Sound.status.PLAYING : Sound.status.PAUSED}
                loop={true}
            />
            <Hero/>

            <AnimationOnScroll initiallyVisible={true} duration={5} animateIn="animate__swing">
                <div className="wpo-event-text" style={{textAlign: 'center', height: 'auto', margin: '20px', padding: '10px', border: '1px solid #044a47', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px', background: '#80a09c'}}>
                    <p style={{lineHeight: 'normal', fontWeight: 'bold', paddingTop: '10px', color: 'white', fontFamily: "photograph", fontSize: '23px', marginBottom: '10px'}}>Assalamu'alaikum Wr. Wb.</p>
                    <p style={{lineHeight: 'normal', fontWeight: 'normal', color: 'white', fontFamily: "Gilda Display", fontSize: '13px'}}>Tanpa mengurangi rasa hormat. Kami mengundang Bapak/Ibu/Saudara/i serta kerabat sekalian untuk menghadiri acara pernikahan kami:</p>
                </div>
            </AnimationOnScroll>

            <CoupleSection2/>

            <AnimationOnScroll initiallyVisible={true} duration={5} animateIn="animate__swing">
                <div style={{backgroundImage: `url(${hImg3})`, padding: '20px', paddingTop: '50px', paddingBottom: '50px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '10px', marginBottom: '30px'}}>
                    <div className="wpo-event-text" style={{textAlign: 'center', height: 'auto', padding: '10px', border: '1px solid #044a47', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px', background: 'rgb(127 159 155 / 90%)'}}>
                        <p style={{lineHeight: 'normal', fontWeight: 'bold', color: 'white', fontFamily: "Gilda Display", fontSize: '13px', paddingTop: '20px'}}>وَمِنْ اٰيٰتِهٖٓ اَنْ خَلَقَ لَكُمْ مِّنْ اَنْفُسِكُمْ اَزْوَاجًا لِّتَسْكُنُوْٓا اِلَيْهَا وَجَعَلَ بَيْنَكُمْ مَّوَدَّةً وَّرَحْمَةً ۗاِنَّ فِيْ ذٰلِكَ لَاٰيٰتٍ لِّقَوْمٍ يَّتَفَكَّرُوْنَ</p>
                        <p style={{lineHeight: 'normal', color: 'white', fontFamily: "Gilda Display", fontSize: '13px', paddingTop: '5px'}}>"Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir." <br/> <br/> <b>Qs Ar-Rum : 21</b></p>
                    </div>
                </div>
            </AnimationOnScroll>

            <EventSection name={dataUser?.name} dataUser={dataUser}/>

            <div style={{padding: '20px', backgroundColor: '#dae1d7', marginBottom: '20px'}}>
                <PortfolioSection2/>
            </div>

            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
            <div style={{padding: '15px', marginBottom: '30px'}}>
                <div style={{border: '2px solid transparent', width: '100%', borderTopLeftRadius: '25px', borderBottomRightRadius: '25px', textAlign: 'center', padding: '10px', background: 'rgba(129, 162, 157, 0.7)', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 50px 5px' }}>
                    <h3 style={{fontFamily: 'photograph', fontSize: '2rem', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold'}}>Protokol &nbsp;Kesehatan</h3>
                    <p style={{fontFamily: 'Gilda Display', fontSize: '13px', lineHeight: 'normal', paddingBottom: '20px', color: 'white'}}>Demi memutus mata rantai penyebaran virus Covid-19 kami menerapkan protokol kesehatan dalam acara pernikahan kami. Kami harap Bapak/Ibu/Saudara/i mematuhi protokol kesehatan demi kenyamanan bersama</p>
                    <Row>
                        <Col
                            xs="6"
                            sm="6"
                            md="6"
                            lg="6"
                            xl="6"
                            style={{textAlign: 'center'}}>
                                <img src={socialDistancing} alt="" style={{width: '50px'}}/>
                                <h3 style={{fontFamily: 'Gilda Display', paddingTop: '10px', fontWeight: '700 !important', color: 'white', fontSize: '13px'}}>Menjaga Jarak</h3>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'white', fontSize: '13px', lineHeight: 'normal'}}>Saling Menjaga Jarak ketika menghadiri acara</p>
                        </Col>
                        <Col
                            xs="6"
                            sm="6"
                            md="6"
                            lg="6"
                            xl="6"
                            style={{textAlign: 'center'}}>
                                <img src={hands} alt="" style={{width: '50px'}}/>
                                <h3 style={{fontFamily: 'Gilda Display', paddingTop: '10px', fontWeight: '700 !important', color: 'white', fontSize: '13px'}}>Tidak Bersalaman</h3>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'white', fontSize: '13px', lineHeight: 'normal'}}>Menghindari kontak fisik dengan tidak bersalaman</p>
                        </Col>
                        <Col
                            xs="6"
                            sm="6"
                            md="6"
                            lg="6"
                            xl="6"
                            style={{textAlign: 'center'}}>
                                <img src={mask} alt="" style={{width: '50px'}}/>
                                <h3 style={{fontFamily: 'Gilda Display', paddingTop: '10px', fontWeight: '700 !important', color: 'white', fontSize: '13px'}}>Menggunakan Masker</h3>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'white', fontSize: '13px', lineHeight: 'normal'}}>Wajib menggunakan masker/face shield</p>
                        </Col>
                        <Col
                            xs="6"
                            sm="6"
                            md="6"
                            lg="6"
                            xl="6"
                            style={{textAlign: 'center'}}>
                                <img src={handSanitizer} alt="" style={{width: '50px'}}/>
                                <h3 style={{fontFamily: 'Gilda Display', paddingTop: '10px', fontWeight: '700 !important', color: 'white', fontSize: '13px'}}>Mencuci Tangan</h3>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'white', fontSize: '13px', lineHeight: 'normal'}}>Mencuci tangan sebelum memasuki resepsi acara</p>
                        </Col>
                    </Row>
                </div>
            </div>
            </AnimationOnScroll>
            
            <div id="comment" className='comment_container' style={{padding: '20px', marginBottom: '50px', paddingTop: '40px'}}>
                <SectionTitle MainTitle={'Komentar' + '\xa0\xa0' + 'Kamu'} />
                <div style={{
                    border: '2px solid transparent', 
                    width: '100%', 
                    borderTopLeftRadius: '10px', 
                    borderBottomRightRadius: '10px', 
                    // textAlign: 'center',
                    background: 'white', 
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 50px 5px',
                    marginBottom: '20px',
                    padding: '10px', 
                    paddingTop: '20px',
                    paddingBottom: '20px'}}>
                        {
                            JSON.stringify(dataComment) !== '{}' && JSON.stringify(dataComment) !== '[]' ?
                            dataComment.map((data, index) => {
                                return <div style={{borderBottom: '1px solid rgb(218, 225, 215)'}} key={index}>
                                    <h3 style={{fontFamily: 'Gilda Display', paddingTop: '10px', fontWeight: '700 !important', color: data.presence_status == 1 ? 'green' : 'lightgrey', fontSize: '13px', marginBottom: '3px'}}>{data.name} <FontAwesome name={data.presence_status == 1 ? 'thumbs-up' : null}/></h3>
                                    <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'grey', fontSize: '12px', padding: '0px', margin: '0px', marginBottom: '3px'}}>{data.comment}</p>
                                </div>
                            }) : ['0'].map((data, index) => {
                                return <div style={{borderBottom: '1px solid rgb(218, 225, 215)'}} key={index}>
                                    <p style={{fontFamily: 'Gilda Display', fontWeight: '400 !important', color: 'grey', fontSize: '12px', padding: '0px', margin: '0px', marginBottom: '3px'}}>Tidak ada data</p>
                                </div>
                            })
                        }
                </div>
            </div>
            <RSVP name={dataUser?.name} dataUser={dataUser}/>
            <PartnerSection/>
            <Footer/> 
            <div style={{width: '100%', height: '75px', zIndex: '100', bottom: '0px', position: 'fixed', padding: '5px', textAlign: '-webkit-center'}}>
                <div className='bottom-nav' 
                // style={{
                //     width: '92%', 
                //     background: 'rgba(254, 254, 254, 0.70)',
                //     borderRadius: '10px',
                //     border: '.5px solid white', 
                //     height: '60px',
                //     boxShadow: '7px 7px 11px -7px rgba(0,0,0,0.46)',
                //     WebkitBoxShadow: '7px 7px 11px -7px rgba(0,0,0,0.46)',
                //     MozBoxShadow: '7px 7px 11px -7px rgba(0,0,0,0.46)'
                // }}
                >
                    {/* <i className={"ti-home"} style={{fontSize: '35px'}}></i> */}
                    <Row style={{paddingTop: '8px'}}>
                        {/* <Col
                            xs="1"
                            sm="1"
                            md="1"
                            lg="1"
                            xl="1">
                                <i className={"ti-home"} style={{fontSize: '35px'}}></i>
                        </Col> */}
                        <Col
                            xs="3"
                            sm="3"
                            md="3"
                            lg="3"
                            xl="3" style={{alignSelf: 'center'}}>
                                <div style={{background: 'rgb(152 187 177)', borderRadius: '50px', width: '30px', height: '30px'}}>
                                    <AnchorLink href="#scrool"><img src={iconHomeCouple} alt="" style={{width: '20px', verticalAlign: '-webkit-baseline-middle'}}/></AnchorLink>
                                </div>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '900 !important', color: 'black', fontSize: '10px', lineHeight: 'normal', paddingTop: '3px'}}>Dasbor</p>
                        </Col>
                        <Col
                            xs="2"
                            sm="2"
                            md="2"
                            lg="2"
                            xl="2">
                                <div style={{background: 'rgb(152 187 177)', borderRadius: '50px', width: '30px', height: '30px'}}>
                                <AnchorLink href="#couple"><img src={iconCouple} alt="" style={{width: '20px', verticalAlign: '-webkit-baseline-middle'}}/></AnchorLink>
                                </div>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '900 !important', color: 'black', fontSize: '10px', lineHeight: 'normal', paddingTop: '3px'}}>Kami</p>
                        </Col>
                        <Col
                            xs="2"
                            sm="2"
                            md="2"
                            lg="2"
                            xl="2">
                                <div style={{background: 'rgb(152 187 177)', borderRadius: '50px', width: '30px', height: '30px'}}>
                                    <AnchorLink href="#events"><img src={iconSchedule} alt="" style={{width: '20px', verticalAlign: '-webkit-baseline-middle'}}/></AnchorLink>
                                </div>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '900 !important', color: 'black', fontSize: '10px', lineHeight: 'normal', paddingTop: '3px'}}>Jadwal</p>
                        </Col>
                        <Col
                            xs="2"
                            sm="2"
                            md="2"
                            lg="2"
                            xl="2">
                                <div style={{background: 'rgb(152 187 177)', borderRadius: '50px', width: '30px', height: '30px'}}>
                                    <AnchorLink href="#gallery"><img src={iconGallery} alt="" style={{width: '20px', verticalAlign: '-webkit-baseline-middle'}}/></AnchorLink>
                                </div>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '900 !important', color: 'black', fontSize: '10px', lineHeight: 'normal', paddingTop: '3px'}}>Foto</p>
                        </Col>
                        <Col
                            xs="3"
                            sm="3"
                            md="3"
                            lg="3"
                            xl="3">
                                <div style={{background: 'rgb(152 187 177)', borderRadius: '50px', width: '30px', height: '30px'}}>
                                    <AnchorLink href="#comment"><img src={iconComment} alt="" style={{width: '20px', verticalAlign: '-webkit-baseline-middle'}}/></AnchorLink>
                                </div>
                                <p style={{fontFamily: 'Gilda Display', fontWeight: '900 !important', color: 'black', fontSize: '10px', lineHeight: 'normal', paddingTop: '3px'}}>Komentar</p>
                        </Col>
                        {/* <Col
                            xs="1"
                            sm="1"
                            md="1"
                            lg="1"
                            xl="1">
                                <i className={"ti-home"} style={{fontSize: '35px'}}></i>
                        </Col> */}
                    </Row>
                </div>
            </div>
            <Scrollbar statusPause={statusPause} changeStatusPause={() => setStatusPause(!statusPause)} statusUnhide={true} />
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
      dataUserOpenInvitation: state.wishList.dataUserOpenInvitation,
      dataUserPath: state.wishList.dataUserPath,
      dataAllComments: state.wishList.dataAllComments,
      dataPutComment: state.wishList.dataPutComment
    };
};

export default connect(mapStateToProps, {
    getDataUserOpenInvitation,
    getAllDataUserComment,
    postDataCopyDonasi,
})(HomePage);