import React from 'react'
import SectionTitle from '../SectionTitle'
import pImg1 from '../../images/portfolio/1.jpg'
import pImg2 from '../../images/portfolio/2.jpg'
import pImg3 from '../../images/portfolio/3.jpg'
import pImg4 from '../../images/portfolio/4.jpg'
import pImg5 from '../../images/portfolio/5.jpg'
import pImg6 from '../../images/portfolio/6.jpg'
import pImg7 from '../../images/portfolio/7.jpg'
import pImg8 from '../../images/portfolio/8.jpg'
import pImg9 from '../../images/portfolio/11.jpg'
import prewedd_1 from '../../images/portfolio/image_prewed_1.jpg'
import prewedd_2 from '../../images/portfolio/image_prewed_2.jpg'
import pImg10 from '../../images/slider/s1.jpg'
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

const Portfolios = [
    {
        Pimg:prewedd_1,
    },
    {
        Pimg:prewedd_2,
    },
] 

const PortfolioSection2 = (props) => {
    return(
        <section className={`wpo-portfolio-section section-padding ${props.pClass}`} id="gallery" style={{paddingBottom: '20px', paddingTop: '20px'}}>
            <div className="container">
                <SectionTitle MainTitle={'Foto' + '\xa0\xa0\xa0' + 'Pranikah'}/>
                <div className="sortable-gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix">
                                {Portfolios.map((portfolio, pitem) => (

                                    <div className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.Pimg}
                                                image={portfolio.Pimg}
                                            />
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    <p style={{fontSize: '13px', margin: '0px', color: 'grey', fontWeight: 'bold'}}>Klik gambar untuk membesarkan...</p>
                </div>
            </div>
        </section>
    )
}

export default PortfolioSection2;