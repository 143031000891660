import { 
  ADD_TO_WISHLIST, 
  REMOVE_FROM_WISHLIST, 
  GET_DATA_USER_OPEN_INVITATION, 
  SET_DATA_PATH_USER, 
  GET_DATA_ALL_USER, 
  PUT_COMMENT, 
  CREATE_DATA_BLOG,
  GET_DATA_BLOG,
  GET_DATA_ONE_BLOG,
  GET_DATA_ONE_BLOG_ERROR
} from "../actions/type";
import { toast } from "react-toastify";

const init = {
  w_list: [],
  dataUserOpenInvitation: null,
  dataUserPath: null,
  dataAllComments: null,
  dataPutComment: null,
  dataCreateBlog: {},
  dataGetBlog: [],
  dataGetOneBlog: {},
  dataErrorGetOneBlog: null
};

export const wishListReducer = (state = init, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      const productId = action.product.id;
      if (
        state.w_list.findIndex((product) => product.id === productId) !== -1
      ) {
        toast.error("Item Removed from Wishlist");
        const w_list = state.w_list.filter(
          (product) => product.id !== productId
        );
        return { ...state, w_list };
      }
      toast.success("Item Added to Wishlist");
      return {
        ...state,
        w_list: [
          ...state.w_list,
          {
            ...action.product,
          },
        ],
      };
    case REMOVE_FROM_WISHLIST:
      const w_productId = action.id;
      const w_list = state.w_list.filter(
        (product) => product.id !== w_productId
      );
      return { ...state, w_list };
    case GET_DATA_USER_OPEN_INVITATION:
      const dataUserOpenInvitation = action
      return { ...state, dataUserOpenInvitation };
    case SET_DATA_PATH_USER:
      const dataUserPath = action
      return { ...state, dataUserPath };
    case GET_DATA_ALL_USER:
      const dataAllComments = action
      return { ...state, dataAllComments };
    case PUT_COMMENT:
      const dataPutComment = action
      return { ...state, dataPutComment };
    case CREATE_DATA_BLOG:
      const dataCreateBlog = action
      return { ...state, dataCreateBlog };
    case GET_DATA_BLOG:
      const dataGetBlog = action
      return { ...state, dataGetBlog };
    case GET_DATA_ONE_BLOG:
      const dataGetOneBlog = action.data.data
      return { ...state, dataGetOneBlog };
    case GET_DATA_ONE_BLOG_ERROR:
      const dataErrorGetOneBlog = action
      return { ...state, dataErrorGetOneBlog };
    default:
      return state;
  }
};

export default wishListReducer;
