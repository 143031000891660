import React from 'react';
import { Row, Col } from 'reactstrap';
import Button from "@material-ui/core/Button";
import '../index.css'

const WriteArticle = (props) => {
    return (
        <>
            <div className='div-judul-artikel'>
                <p className='p-judul-postingan-right'>
                    Judul Postingan
                </p>
                <textarea 
                    className="form-control text-area-custom" 
                    placeholder="Judul / Subject" 
                    type="text" 
                    value={props.postTitle}
                    onChange={(e) => props.setPostTitle(e.target.value)}/>
            </div>
            <div className='div-judul-artikel'>
                <p className='p-judul-postingan-right'>
                    Cover Postingan
                </p>
                {
                    props.postCover !== '' ?
                    <img src={props.postCover} alt="..." style={{}} /> :
                    null
                }
                <div className='dropbox'>
                    <input 
                        type="file"
                        multiple={false}
                        accept="image/*" 
                        className="input-file" 
                        onChange={(e) => props.fileChangeCover(e)}/>
                    <div style={{ textAlign: 'center', height: '200px' }}>
                        <p>Tambah Gambar Disini</p>
                    </div>
                </div>

            </div>
            {
                props.content.map((dataContent, index) => {
                    return (
                        <div key={index} style={{ paddingBottom: '20px' }}>
                            <p className='p-judul-postingan-left'>CONTENT {index.toString()}</p>
                            <p  className='p-judul-postingan-right'>Pilih Tipe Konten</p>
                            <select 
                                name="guest" 
                                className="form-control" 
                                value={dataContent.class} 
                                onChange={(e) => {props.changeClass(index, e.target.value)}} 
                                style={{ marginBottom: '10px' }}>
                                    <option>format-standart-content</option>
                                    <option>format-quote</option>
                                    <option>format-image</option>
                            </select>
                            {
                                dataContent.class === "format-image" ?
                                <div>
                                    {
                                        dataContent.content.length > 1 ?
                                        <Row>
                                            {
                                                dataContent.content.map((dataImage, indexImage) => {
                                                    return <Col
                                                        xs="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                        xl="6"
                                                        key={"0"+indexImage}
                                                        style={{
                                                            textAlign: dataImage.align, 
                                                            paddingBottom: '20px', 
                                                            paddingTop: '20px'
                                                        }}>
                                                        <img src={dataImage.base64} alt="..." style={{}} />
                                                    </Col>
                                                })
                                            }
                                        </Row> : dataContent.content.length !== 0 ?
                                        <img src={dataContent.content[0]?.base64 ? dataContent.content[0]?.base64 : ''} alt="..." style={{}} /> : 
                                        null
                                    }
                                    <div className='dropbox'>
                                        <input 
                                            type="file"
                                            multiple
                                            accept="image/*" 
                                            className="input-file" 
                                            onChange={(e) => props.filesChangeLocal(e, index)}/>
                                        <div style={{ textAlign: 'center', height: '200px' }}>
                                            <p>Tambah Gambar Disini</p>
                                        </div>
                                    </div>
                                </div> :
                                <div>
                                    <p style={{ marginBottom: '0px', textAlign: 'right', fontSize: '.8rem', fontWeight: '500' }}>Tulis Konten</p>
                                    <textarea 
                                        onChange={(e) => props.changeContentText(index, e.target.value)} 
                                        className="form-control" 
                                        value={dataContent.content}
                                        placeholder="Judul / Subject" 
                                        type="url" 
                                        style={{ width: '100%', fontSize: '.9rem', marginBottom: '10px', height: '100px' }}/>
                                </div>
                            }
                            <Row>
                                <Col xs="12" sm="12" md="6" lg="6" xl="6" style={{ paddingTop: '10px' }}>
                                    <Button className="collapseBtn" style={{ background: 'blue', color: 'white' }} fullWidth onClick={() => props.addContent(index)}>
                                        Tambah Paragraf
                                    </Button>
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="6" xl="6" style={{ paddingTop: '10px' }}>
                                    <Button className="collapseBtn" style={{ background: 'red', color: 'white' }} fullWidth onClick={() => props.deleteContent(index)}>
                                        Hapus Paragraf
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }
        </>
    )
}

export default WriteArticle;