//default action
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const INCREMENT_QUANTITY = 'INCREMENT_QUANTITY';
export const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE_LIST = 'REMOVE_FROM_COMPARE_LIST';

//! setDataGlobal
export const SET_DATA_PATH_USER = 'SET_DATA_PATH_USER';

//! get
export const GET_DATA_USER_OPEN_INVITATION = 'GET_DATA_USER_OPEN_INVITATION';
export const GET_DATA_ALL_USER = 'GET_DATA_ALL_USER';
export const PUT_COMMENT = 'PUT_COMMENT';

//! error
export const ERROR_DATA_USER_OPEN_INVITATION = 'ERROR_DATA_USER_OPEN_INVITATION';

//! blog
export const CREATE_DATA_BLOG = 'CREATE_DATA_BLOG';
export const ERROR_DATA_CREATE_BLOG = 'ERROR_DATA_CREATE_BLOG';
export const GET_DATA_BLOG = 'GET_DATA_BLOG';
export const GET_DATA_BLOG_ERROR = 'GET_DATA_BLOG_ERROR';
export const GET_DATA_ONE_BLOG = 'GET_DATA_ONE_BLOG';
export const GET_DATA_ONE_BLOG_ERROR = 'GET_DATA_ONE_BLOG_ERROR';