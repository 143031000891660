import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
// import VideoModal from '../../components/ModalVideo'
// import { dataSampe } from './dataSample';
// // import { Button, Dialog, Grid, } from '@material-ui/core'
// import tree from '../../images/contact/1.png'
// // import hImg3 from '../../images/slider/s1_2.jpg'
// import hImg5 from '../../images/slider/shape4.png'
import { Row, Col } from 'reactstrap';
// import frameBallon from '../../images/blog/frame_ballon_gold.png'



const BlogList = (props) => {
    const [patchClick, setPatchClick] = useState(0);
    
    useEffect(() => {
        var articleId = localStorage.getItem('article_id')
        setPatchClick(articleId ? articleId : 0)
    }, [])
    const ClickHandler = (value) =>{
        localStorage.setItem('article_id', value)
        // window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-blog-pg-section section-padding" style={{  }}>
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <Row>
                                {
                                    props.allDataBlog.map((dataBlog, indexBlog) => {
                                        return (
                                            <Col
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6"
                                                xl="6"
                                                key={indexBlog}>
                                                <div className={`post`}>
                                                    <div className="entry-media video-holder custom-blog-image">
                                                        {/* <div className='css-custom-background'>
                                                            <img src={frameBallon} alt="tree"/>
                                                        </div> */}
                                                        {/* <div className='css-custom-background-top'>
                                                            <img src={'https://png.pngtree.com/png-clipart/20220404/original/pngtree-golden-birthday-celebration-celebration-decorative-balloons-png-image_7510430.png'} alt="tree"/>
                                                        </div> */}
                                                        <img className='css-custom-image-cover' src={dataBlog.table_blog_cover} alt=""/>
                                                    </div>

                                                    <div className="entry-meta" style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                        <ul>
                                                            <li><i className="fi flaticon-user"> </i> 
                                                                By <Link onClick={ClickHandler} to={`/blog-single/${dataBlog.table_blog_id}`}>{dataBlog.writer}</Link> 
                                                            </li>
                                                            <li><i className="fi flaticon-calendar"></i> {dataBlog.table_blog_create_at}</li>
                                                        </ul>
                                                    </div>

                                                    <div className="entry-details">
                                                        <h3><Link onClick={ClickHandler} to={`/blog-single/${dataBlog.table_blog_id}`}>{dataBlog.table_blog_title}</Link></h3>
                                                        {/* <p>{dataBlog.sort_description}</p> */}
                                                        <Link 
                                                            onClick={() => ClickHandler(dataBlog.table_blog_id)} 
                                                            to={`/blog-single/${dataBlog.table_blog_id}`} 
                                                            className={patchClick.toString() === dataBlog.table_blog_id.toString() ? "read-more-change" : "read-more"}>
                                                                Baca Selanjutnya...
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    {
                                        props.mapArray.map((data, indexPagination) => {
                                            return <li 
                                                className={data === props.paginationCount ? "active" : ""} 
                                                onClick={() => props.funSetPaginationCount(data)}
                                                key={indexPagination}>
                                                <Link to="">{data}</Link>
                                            </li>
                                        })
                                    }
                                    {/* <li className="active"><Link to="">1</Link></li>
                                    <li><Link to="">2</Link></li>
                                    <li><Link to="">3</Link></li> */}
                                    <li>
                                        <Link to="" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>

     )
        
}

export default BlogList;
