import React from 'react'
import FontAwesome from "../../components/UiStyle/FontAwesome";
import {toast} from "react-toastify";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/image_griya_resepsi.jpg'
import sImg2 from '../../images/event/image_griya.jpg'
import sImg3 from '../../images/event/3.jpg'
import LocationMap from '../Modal'
import { Button } from '@material-ui/core'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { connect } from "react-redux";
import {
    getDataUserOpenInvitation,
    getAllDataUserComment,
    postDataCopyDonasi,
} from "../../store/actions/action";


const Events = [
    {
        Simg: sImg2,
        title:'Akad',
        buildingName: 'Griya Ardhya Garini',
        li1:'Sabtu, 21 Januari, 2023 ...... AM - ....... AM',
        li2:'Jl. Rajawali Raya, RT.5/RW.11, Halim Perdana Kusumah, Kec. Makasar, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13610',
    },
    {
        Simg: sImg1,
        title:'Resepsi',
        buildingName: 'Griya Ardhya Garini',
        li1:'Sabtu, 21 Januari, 2023 ...... AM - ....... AM',
        li2:'Jl. Rajawali Raya, RT.5/RW.11, Halim Perdana Kusumah, Kec. Makasar, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13610',
    },
]

const Bank = [
    {
        imageBank: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Bank_Mandiri_logo_2016.svg/2560px-Bank_Mandiri_logo_2016.svg.png',
        noRek: '1290010503783',
        atasNama: 'A/n Kunti Desy Wulandari'
    },
    {
        imageBank: 'https://www.banksinarmas.com/id/public/revamp/logoj.png',
        noRek: '0053083285',
        atasNama: 'A/n Moch Galih Indraprahastha'
    }
]

const EventSection = (props) => {
    const funcCopy = (value, bankorpackage) => {
        navigator.clipboard.writeText(value)
        let objectSend = {
            user_teman_hidup_id: props.dataUser.user_teman_hidup_id,
            status_copy_bank_account: `${bankorpackage} | ${value}`
        }
        props.postDataCopyDonasi(objectSend)
        toast.success('Copy Success');
    }

    return (
        <div>
            <section className={`wpo-event-section section-padding ${props.eClass}`} id="events" style={{paddingBottom: '30px'}}>
                <div className="container">
                    <SectionTitle MainTitle={'Waktu & Tempat'} />
                    <div className="wpo-event-wrap">
                        <div className="row">
                            {Events.map((event, eitem) => (
                                <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                    <AnimationOnScroll animateIn="animate__tada">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <img src={event.Simg} alt="" style={{borderTopRightRadius: '50px', borderBottomLeftRadius: '50px', border: '2px solid lightgrey'}}/>
                                        </div>
                                        <div className="wpo-event-text">
                                            <h2>{event.title}</h2>
                                            <p style={{lineHeight: 'normal', fontWeight: 'normal', color: 'grey', fontFamily: "Gilda Display", fontSize: '15px', paddingTop: '5px', borderBottom: '1px solid grey', paddingBottom: '5px'}}>
                                                {event.title == 'Akad' ? 'Sabtu, 21 Januari, 07:00 WIB' : props.dataUser?.time_of_invitation == 0 ? 'Sabtu, 21 Januari, 2023 11:00 WIB - 12:00 WIB' : 'Sabtu, 21 Januari, 2023 12:00 WIB - 13:00 WIB'}
                                            </p>
                                            <h3 style={{}}>{event.buildingName}</h3>
                                            <p style={{lineHeight: 'normal', fontWeight: 'normal', fontFamily: "Gilda Display", fontSize: '15px', paddingTop: '5px'}}>{event.li2}</p>
                                            
                                            <ul>
                                                <li><LocationMap/></li>
                                            </ul>
                                        </div>
                                    </div>
                                    </AnimationOnScroll>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>


            <section className={`wpo-event-section-for-amplop section-padding ${props.eClass}`} id="events" style={{paddingTop: '0px'}}>
                <div className="container">
                    <SectionTitle MainTitle={'Dompet' + '\xa0\xa0' + 'Digital'} />
                    <div className="wpo-event-wrap">
                        <div className="row">
                            {Bank.map((event, eitem) => (
                                <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img" style={{textAlign: 'center', paddingTop: '20px', paddingBottom: '20px'}}>
                                            <img src={event.imageBank} alt="" height={'30px'}/>
                                        </div>
                                        <div className="wpo-event-text">
                                            <h3 style={{margin: '0px'}}>{event.noRek}</h3>
                                            <p style={{lineHeight: 'normal', fontWeight: 'normal', color: 'black', fontFamily: "Gilda Display", fontSize: '15px', paddingTop: '5px'}}>{event.atasNama}</p>
                                            
                                            <div>
                                                <h3 className='button-click' style={{
                                                    fontFamily: 'Gilda Display', 
                                                    paddingTop: '10px', 
                                                    fontWeight: '700 !important', 
                                                    color: 'green', 
                                                    fontSize: '15px', 
                                                    marginBottom: '3px', 
                                                    cursor: 'pointer'}}
                                                    onClick={() => {funcCopy(event.noRek, event.noRek == '1290010503783' ? 'Mandiri' : 'Sinarmas')}}>
                                                        Copy &nbsp;
                                                    <FontAwesome name={'copy'}/></h3>
                                            </div>
                                            {/* <ul>
                                                <li><LocationMap/></li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="col col-lg-4 col-md-6 col-12" key={'key'}>
                                <div className="wpo-event-item">
                                    {/* <div className="wpo-event-img" style={{textAlign: 'center', paddingTop: '20px', paddingBottom: '20px'}}>
                                        Kirim Hadiah
                                    </div> */}
                                    <div className="wpo-event-text">
                                        <h2 style={{marginBottom: '5px',}}>Alamat</h2>
                                        <h3 style={{}}>Kunti Desy Wulandari</h3>
                                        <p style={{lineHeight: 'normal', fontWeight: 'normal', color: 'black', fontFamily: "Gilda Display", fontSize: '15px', paddingTop: '5px', paddingBottom: '5px'}}>
                                            Kampung baru 1 RT 10 RW 5 no 2 kelurahan Halim perdana kusuma kec Makasar Jakarta timur 13610 (Rumah Tembok Oren)
                                        </p>
                                        <div>
                                            <h3 className='button-click' style={{
                                                fontFamily: 'Gilda Display', 
                                                paddingTop: '10px', 
                                                fontWeight: '700 !important', 
                                                color: 'green', 
                                                fontSize: '15px', 
                                                marginBottom: '3px', 
                                                cursor: 'pointer'}}
                                                onClick={() => {funcCopy('Penerima: Kunti Desy Wulandari \nKampung baru 1 RT 10 RW 5 no 2 kelurahan Halim perdana kusuma kec Makasar Jakarta timur 13610 (Rumah Tembok Oren)', 'Alamat')}}>
                                                    Copy &nbsp;
                                                <FontAwesome name={'copy'}/></h3>
                                        </div>
                                        
                                        {/* <ul>
                                            <li><LocationMap/></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

// export default EventSection;
const mapStateToProps = (state) => {
    return {
      dataUserOpenInvitation: state.wishList.dataUserOpenInvitation,
      dataUserPath: state.wishList.dataUserPath,
      dataAllComments: state.wishList.dataAllComments,
      dataPutComment: state.wishList.dataPutComment
    };
};

export default connect(mapStateToProps, {
    getDataUserOpenInvitation,
    getAllDataUserComment,
    postDataCopyDonasi,
})(EventSection);