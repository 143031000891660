/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import ViewArticle from './viewArticle'
import WriteArticle from './writeArticle'
import '../index.css'
import imageCompression from 'browser-image-compression';
const options = {
    maxSizeMB: .05,
    useWebWorker: true,
}

const AddArticle = (props) => {
    const [content, setContent] = useState([]);
    const [postTitle, setPostTitle] = useState('');
    const [postCover, setPostCover] = useState('');
    const [idExisting, setIdExisting] = useState('');

    const { draft } = useParams()

    useEffect( () => {
        setContent([{
            content: '',
            class: 'format-standart-content',
        }])
        window.scrollTo(10, 0);
        if (draft) {
            getDataDraft()
        }
    }, [])

    const getDataDraft = async () => {
        setIdExisting(draft)
        let localstorage = await localStorage.getItem('article_draft')
        localstorage = await JSON.parse(localstorage)
        let findDraft = await localstorage.find((data) => {return data.id === draft})
        setPostTitle(findDraft.postTitle)
        setPostCover(findDraft.postCover)
        setContent(findDraft.content)
    }

    const addContent = (index) => {
        let newContent = []
        for (let i = 0; i < content.length; i++) {
            newContent.push(content[i])
        }
        newContent.push({
            content: ``,
            class: 'format-standart-content',
        })
        setContent(newContent)
    }

    const deleteContent = (index) => {
        let newContent = []
        for (let i = 0; i < content.length; i++) {
            newContent.push(content[i])

        }
        newContent.splice(index, 1)
        setContent(newContent)
    }

    const changeClass = (index, e) => {
        let newContent = []
        for (let i = 0; i < content.length; i++) {
            if (i === index) {
                if (e === 'format-image') {
                    content[i].class = e
                    content[i].content = []
                    newContent.push(content[i])
                } else {
                    content[i].class = e
                    newContent.push(content[i])
                }
            } else {
                newContent.push(content[i])
            }
        }
        setContent(newContent)
    }

    const changeContentText = (index, e) => {
        let newContent = []
        for (let i = 0; i < content.length; i++) {
            if (i === index) {
                content[i].content = e
                newContent.push(content[i])
            } else {
                newContent.push(content[i])
            }
        }
        setContent(newContent)
    }

    const filesChangeLocal = async (element, index) => {
        try {
            props.setOpen(true)
            var imageUrl = []
            let newContent = []
            for (let i = 0; i < element.target.files.length; i++) {
                const compressedFile = await imageCompression(element.target.files[i], options);
                let base64Encoded = await getBase64(compressedFile)
                imageUrl.push({
                    base64: await base64Encoded,
                    photo_link: URL.createObjectURL(element.target.files[i]),
                    photo_name: element.target.files[i].name,
                    weight: '500',
                    height: '300',
                    align: 'center',
                })
            }
            for (let i = 0; i < content.length; i++) {
                if (i === index) {
                    content[i].content = imageUrl
                    newContent.push(content[i])
                } else {
                    newContent.push(content[i])
                }
            }
            await setContent(newContent)
            props.setOpen(false)
        } catch (error) {
            // console.error(error)
        }
    }

    const fileChangeCover = async (element) => {
        try {
            props.setOpen(true)
            const compressedFile = await imageCompression(element.target.files[0], options);
            let base64Encoded = await getBase64(compressedFile)
            await setPostCover(base64Encoded)
            props.setOpen(false)
        } catch (error) {
            // console.error(error)
        }
    }

    async function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
        })
    }

    const saveDraft = () => {
        let objectDraft = {
            postTitle: postTitle,
            content: content,
            postCover: postCover,
        }
        props.saveDraft(objectDraft)
    }

    const saveToServer = () => {
        var newDate = new Date()
        var dateNow = `${newDate.getFullYear()}/${newDate.getMonth()}/${newDate.getDate()} ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`
        
        let objectDraft = {
            postTitle: postTitle,
            content: content,
            postCover: postCover,
            createAt: dateNow,
            writer: 'Prahastha'
        }
        props.saveToServer(objectDraft)
    }

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container post">
                <h2>Formulir Penulisan Cerita...</h2>
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        style={{ paddingTop: '10px' }}>
                            <WriteArticle 
                                postTitle={postTitle}
                                postCover={postCover}
                                setPostTitle={(e) => setPostTitle(e)} 
                                changeClass={(index, e) => changeClass(index, e)} 
                                content={content}
                                filesChangeLocal={(e, index) => filesChangeLocal(e, index)}
                                changeContentText={(index, e) => changeContentText(index, e)}
                                addContent={(index) => addContent(index)}
                                deleteContent={(index) => deleteContent(index)}
                                fileChangeCover={(e) => fileChangeCover(e)}/>
                            
                    </Col>
                    <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        style={{ paddingTop: '10px' }}>
                            <ViewArticle content={content} postTitle={postTitle} saveDraft={() => saveDraft()} postCover={postCover} saveToServer={() => saveToServer()}/>
                    </Col>
                </Row>
            </div>
        </section>
    )

}

export default AddArticle;