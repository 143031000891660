import React from 'react';
import { Link } from 'react-router-dom'

const ContentTextType = (props) => {

  return (
    <div>
      <p style={{ textAlign: 'justify' }}>{props.text}</p>
    </div>  
  )
}

export default ContentTextType;