import * as types from "./type";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  REMOVE_FROM_COMPARE_LIST,
  ADD_TO_COMPARE,
  GET_DATA_USER_OPEN_INVITATION,
  // ERROR_DATA_USER_OPEN_INVITATION,
  SET_DATA_PATH_USER,
  GET_DATA_ALL_USER,
  PUT_COMMENT,
  CREATE_DATA_BLOG,
  ERROR_DATA_CREATE_BLOG,
  GET_DATA_BLOG,
  GET_DATA_BLOG_ERROR,
  GET_DATA_ONE_BLOG,
  GET_DATA_ONE_BLOG_ERROR
} from "./type";
import axios from 'axios'

// const address = 'https://103.157.97.98/teman-hidup-api/'
// const address = 'http://localhost:3000/'
const address = 'http://101.50.2.188:3000/'

const http = axios.create({
  baseURL: address
})

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const addToCart = (product, qty, color, size) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch({
    type: types.ADD_TO_CART,
    product,
    qty,
    color,
    size,
  });
};

export const removeFromCart = (product_id) => (dispatch) => {
  toast.success("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};

export const incrementQuantity = (product_id) => (dispatch) => {
  dispatch({
    type: types.INCREMENT_QUANTITY,
    product_id,
  });
};

export const decrementQuantity = (product_id) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_QUANTITY,
    product_id,
  });
};

export const addToWishList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_WISHLIST,
    product,
  });
};

export const removeFromWishList = (id) => (dispatch) => {
  toast.error("Item removed from WishList");
  dispatch({
    type: REMOVE_FROM_WISHLIST,
    id,
  });
};

export const addToCompareList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_COMPARE,
    product,
  });
};

export const removeFromCompareList = (product) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_COMPARE_LIST,
    product,
  });
};

export const getDataUserOpenInvitation = (data) => (dispatch) => {
  console.log('data')
  http.post(`userth/getData`, {urlpath: data}).then(({data}) => {
    toast.success('Get data user success');
    dispatch({
      type: GET_DATA_USER_OPEN_INVITATION,
      data,
    });
  }).catch((err) => {
    toast.error(JSON.stringify(err));
    dispatch({
      type: GET_DATA_USER_OPEN_INVITATION,
      data: {},
    });
  })
}

export const setDataPathUserOpenInvitation = (data) => (dispatch) => {
  dispatch({
    type: SET_DATA_PATH_USER,
    data,
  });
}

export const getAllDataUserComment = () => (dispatch) => {
  http.get(`userth/getalldata`).then(({data}) => {
    
    dispatch({
      type: GET_DATA_ALL_USER,
      data,
    });
  }).catch((err) => {
    toast.error('Get Data Commets Error');
    dispatch({
      type: GET_DATA_ALL_USER,
      data: [],
    });
  })
}

export const postDataComment = (data) => (dispatch) => {
  http.put(`userth/putData`, data).then(({data}) => {
    toast.success('Comment is send');
    dispatch({
      type: PUT_COMMENT,
      data,
    });
  }).catch((err) => {
    toast.error('Comment not send');
    dispatch({
      type: PUT_COMMENT,
      data: {},
    });
  })
}

export const postDataCopyDonasi = (data) => (dispatch) => {
  http.put(`userth/putData`, data).then(({data}) => {
    // toast.success('Comment is send');
    dispatch({
      type: PUT_COMMENT,
      data,
    });
  }).catch((err) => {
    // toast.error('Comment not send');
    dispatch({
      type: PUT_COMMENT,
      data: {},
    });
  })
}

export const getAllDataUser = () => (dispatch) => {
  http.get(`userth/getdata`).then(({data}) => {
    
    dispatch({
      type: GET_DATA_ALL_USER,
      data,
    });
  }).catch((err) => {
    toast.error('Get Data Commets Error');
    dispatch({
      type: GET_DATA_ALL_USER,
      data: [],
    });
  })
}

export const createBlogTemanHidup = (value) => (dispatch) => {
  console.log(value)
  http.post(`blogtemanhidup`, value).then(({data}) => {
    dispatch({
      type: CREATE_DATA_BLOG,
      data: data,
    });
  }).catch((err) => {
    toast.error('Create Blog Error');
    dispatch({
      type: ERROR_DATA_CREATE_BLOG,
      data: err,
    });
  })
}

export const getAllDataBlog = (value) => (dispatch) => {
  http.get(`blogtemanhidup/${value}`).then(({data}) => {
    dispatch({
      type: GET_DATA_BLOG,
      data,
    });
  }).catch((err) => {
    toast.error('Get Data Blog Error');
    dispatch({
      type: GET_DATA_BLOG_ERROR,
      data: [],
    });
  })
}

export const getOneDataBlog = (value) => (dispatch) => {
  http.get(`blogtemanhidup/blog/${value}`).then(({data}) => {
    dispatch({
      type: GET_DATA_ONE_BLOG,
      data,
    });
  }).catch((err) => {
    toast.error('Get Data Blog Error');
    dispatch({
      type: GET_DATA_ONE_BLOG_ERROR,
      data: [],
    });
  })
}

export const clearDataCreateBlog = (value) => (dispatch) => {
  dispatch({
    type: CREATE_DATA_BLOG,
    data: null,
  });
}