import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog-details/author.jpg'
import gl1 from '../../images/blog/img-3.jpg'
import gl2 from '../../images/blog/img-2.jpg'
import { dataSampe } from './dataSample/index';
import { useParams } from 'react-router-dom'
import BlogSidebar from '../../components/BlogSidebar'
import ContentTextType from './components/contentTextType'
import ContentQuoteType from './components/contentQuoteType'
import ContentImageType from './components/contentImageType'

const BlogSingle = (props) => {

    useEffect(() => {
        window.scrollTo(10, 0);
    }, [])

    const { id } = useParams()

    const BlogDetails = dataSampe.find(item => item.id === Number(id))

    console.log(typeof id)
    console.log(BlogDetails)

    return (
        <section className="wpo-blog-single-section section-padding" style={{ paddingBottom: '30px' }}>
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={props.blogDetails?.table_blog_cover} alt="" />
                                </div>
                                <div className="entry-meta">
                                    {
                                        props.blogDetails?.writer && props.blogDetails?.table_blog_create_at ?
                                        <ul>
                                            <li><i className="fi flaticon-user"></i> By <Link to="/blog-single/1">{props.blogDetails?.writer}</Link> </li>
                                            {/* <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {BlogDetails?.total_comments}</li> */}
                                            <li><i className="fi flaticon-calendar"></i> {props.blogDetails?.table_blog_create_at}</li>
                                        </ul> : null
                                    }
                                </div>
                                <h2>{BlogDetails?.title}</h2>
                                {
                                    props.blogDetails?.sort_description ? 
                                    <blockquote style={{ 
                                        marginTop: '50px', 
                                        marginBottom: '10px', 
                                        padding: '10px', 
                                        paddingTop: '30px', 
                                        fontSize: '16px',
                                        background: '#2e4d76',
                                        color: 'white',
                                        borderRadius: '10px',
                                    }}>{props.blogDetails?.sort_description}</blockquote> : null
                                }
                                {
                                    props.blogDetails?.table_blog_content?.map((data, index) => {
                                        return data.table_blog_content_class === "format-standart-content" ? 
                                        <ContentTextType text={data?.table_blog_content_content} key={index}/> : 
                                        data.table_blog_content_class === "format-quote" ? 
                                        <ContentQuoteType text={data?.table_blog_content_content} key={index}/> :
                                        <ContentImageType data={data} key={index}/>
                                    })
                                }
                            </div>

                            {
                                BlogDetails?.writer ?
                                <div>
                                    <div className="tag-share clearfix" style={{ margin: '0px' }}></div>
                                    <div className="author-box">
                                        <div className="author-avatar">
                                            <Link to="/blog-single/1" target="_blank"><img src={blog6} alt="" /></Link>
                                        </div>
                                        <div className="author-content">
                                            <Link to="/blog-single/1" className="author-name">{BlogDetails?.writer}</Link>
                                            <p>{BlogDetails?.writer_description}</p>
                                        </div>
                                    </div>
                                </div> : null
                            }


                            {/* <div className="more-posts">
                                <div className="previous-post">
                                    <Link to="/blog">
                                        <span className="post-control-link">Previous Post</span>
                                        <span className="post-name">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-left-sidebar">
                                        <span className="post-control-link">Next Post</span>
                                        <span className="post-name">Dignissimos ducimus qui blanditiis praesentiu deleniti atque corrupti quos dolores</span>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft}/> */}
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
