import React from 'react'
import {Link}  from 'react-router-dom'
import Projects from '../../api/projects'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer" style={{paddingBottom: '70px'}}>
            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <div className="wpo-upper-footer">
            <div className="container">
                {/* <div className="row">
                    <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12" style={{marginBottom: '30px'}}> */}
                        
                        <div className="widget about-widget">
                                <div className="logo widget-title" style={{textAlign: 'center', marginBottom: '0px'}}>
                                    <Link onClick={ClickHandler} className="logo" to="/" style={{fontSize: '30px'}}>teman-h<span>i<i className="fa fa-heart" aria-hidden="true" style={{fontSize: '8px'}}></i></span>dup.com</Link>
                                </div>
                                <div className="" style={{textAlign: 'center', marginBottom: '0px'}}>
                                    <span className="logo-two" style={{fontSize: '12px'}}>&copy; 2022 - {new Date().getFullYear()} by:</span> <a onClick={ClickHandler} className="logo-two" href="http://www.prahastha.com" target="_blank" rel="noopener noreferrer" style={{fontSize: '12px'}}>prahastha.com</a><span className="logo-two" style={{fontSize: '12px'}}> & Kunti Desy Wulandari</span>
                                </div>
                            
                            {/* <p>Welcome and open yourself to your truest love this year with us! With the Release
                                Process</p> */}
                            {/* <ul>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                        
                    </div>
                    
                    {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Information</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/portfolio">Portfolio</Link></li>
                                <li><Link onClick={ClickHandler} to="/blog">Latest News</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Our Services</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <p>Would you have any enquiries.Please feel free to contuct us</p>
                                <ul>
                                    <li><i className="fi flaticon-email"></i>Feelings@gmail.com</li>
                                    <li><i className="fi flaticon-phone-call"></i>+888 (123) 869523</li>
                                    <li><i className="fi flaticon-maps-and-flags"></i>New York – 1075 Firs Avenue
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Instagram</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0,6).map((project, pitem) => (
                                    <li key={pitem}><Link onClick={ClickHandler} to={`/project-single/${project.id}`}><img src={project.pimg1} alt=""/></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
                {/* </div>
            </div> */}
        </div>
        </AnimationOnScroll>
        {/* <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy; 2022 teman-hidup.com 
                        <Link onClick={ClickHandler} to="/">wpOceans</Link>. All Rights Reserved.
                        </p>
                        
                    </div>
                </div>
            </div>
        </div> */}
    </footer>
  )
} 

export default Footer;