/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import AddArticle from './components/addArticle'
import { useNavigate } from 'react-router-dom'
import {
    createBlogTemanHidup,
    clearDataCreateBlog,
} from "../../store/actions/action";
import { connect } from "react-redux";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const PostStory =(props) => {
    const push = useNavigate();
    const classes = useStyles();
    const { id, draft } = useParams()
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (props.dataCreateBlog && props.dataCreateBlog.data) {
            console.info('Masuk Dalam Kondisi Siap')
            setOpen(false)
            props.clearDataCreateBlog()
            console.log(props.dataCreateBlog)
            push(-1, { replace: true })
        }
    }, [props.dataCreateBlog])
    
    const saveDraft = async (objectDraft) => {
        objectDraft.last_date = new Date()
        objectDraft.id = new Date()
        let articleDraft = await localStorage.getItem('article_draft')
        if (articleDraft) {
            if (draft) {
                articleDraft = JSON.parse(articleDraft)
                let findDraft = await articleDraft.findIndex((data) => {return data.id === draft})
                articleDraft[findDraft] = objectDraft
                await localStorage.setItem('article_draft', JSON.stringify(articleDraft))
                push(-1, { replace: true })
            } else {
                articleDraft = JSON.parse(articleDraft)
                articleDraft.push(objectDraft)
                await localStorage.setItem('article_draft', JSON.stringify(articleDraft))
                push(-1, { replace: true })
            }
        } else {
            await localStorage.setItem('article_draft', JSON.stringify([objectDraft]))
            push(-1, { replace: true })
        }
    }

    const saveToServer = async (objectSave) => {
        setOpen(true)
        let articleDraft = await localStorage.getItem('article_draft')
        if (articleDraft) {
            articleDraft = JSON.parse(articleDraft)
            let findDraft = await articleDraft.findIndex((data) => {return data.id === draft})
            articleDraft.splice(findDraft, 1)
            if (articleDraft.length > 0) {
                await localStorage.setItem('article_draft', JSON.stringify(articleDraft))
                objectSave.blogname = id
                props.createBlogTemanHidup(objectSave)
            } else {
                await localStorage.removeItem('article_draft')
                objectSave.blogname = id
                props.createBlogTemanHidup(objectSave)
            }
        } else {
            objectSave.blogname = id
            props.createBlogTemanHidup(objectSave)
        }

        

        // objectSave.blogname = id
        // props.createBlogTemanHidup(objectSave)
        // push(-1, { replace: true })
    }

    return (
        <Fragment>
            <Backdrop className={classes.backdrop} open={open} onClick={() => {}}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Navbar />
            <PageTitle 
                pageTitle={'Posting Cerita'} 
                pagesub={`Cerita ${id.split('_').join(' ')}`}/>
            <AddArticle 
                setOpen={(value) => setOpen(value)}
                saveDraft={(objectDraft) => saveDraft(objectDraft)} 
                saveToServer={(objectSave) => saveToServer(objectSave)}/>
            <Footer/>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        dataAllComments: state.wishList.dataAllComments,
        dataCreateBlog: state.wishList.dataCreateBlog
    };
};

export default connect(mapStateToProps, {
    createBlogTemanHidup,
    clearDataCreateBlog,
})(PostStory);