import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Slide from 'react-reveal/Slide';
import coupleImg1 from '../../images/slider/image_prahastha.jpg'
import coupleImg2 from '../../images/slider/image_kunti.jpg'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
 
class CoupleSection2 extends Component {

    render() {
        return(
            <section className={`couple-section-s2 section-padding ${this.props.cClass}`} id="couple" style={{background: 'rgb(15 69 0 / 16%)'}}>
                <div className="container" id="couple">
                    <div className="row align-items-center">
                        <div className="col col-xs-12">
                            <div className="couple-area clearfix">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="couple-item">
                                            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                                                <div className="couple-img">
                                                    <img src={coupleImg2} alt=""/>
                                                </div>
                                            </AnimationOnScroll>
                                            <div className="couple-text">
                                                <h3>Kunti&nbsp;Desy&nbsp;Wulandari,&nbsp;A.MD.Kep</h3>
                                                <p>Putri Ketiga dari (Alm) Bapak Gendut Sutaryo<br/>& Ibu Nunuk Sumaryani</p>
                                                <div className="social">
                                                    <ul>
                                                        {/* <li><Link to="/" style={{color: '#044a47', fontSize: '20px'}}><i className="ti-facebook"></i></Link></li>
                                                        <li><Link to="/" style={{color: '#044a47', fontSize: '20px'}}><i className="ti-twitter-alt"></i></Link></li>
                                                        <li><Link to="/" style={{color: '#044a47', fontSize: '20px'}}><i className="ti-instagram"></i></Link></li> */}
                                                        <li>
                                                            <a 
                                                                href="https://www.instagram.com/kuntiidesy" 
                                                                target="_blank" 
                                                                style={{color: '#044a47', fontSize: '20px'}} rel="noreferrer">
                                                                    <i className="ti-instagram"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2" style={{height: '20px'}}>
                                        <div className="middle-couple-text">
                                            <Slide bottom cascade>
                                                {/* <h2>
                                                    <span>L</span>
                                                    <span>o</span>
                                                    <span>v</span>
                                                    <span>e</span>
                                                </h2> */}
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="couple-item">
                                            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                                                <div className="couple-img">
                                                    <img src={coupleImg1} alt=""/>
                                                </div>
                                            </AnimationOnScroll>
                                            <div className="couple-text">
                                                <h3>Moch&nbsp;Galih&nbsp;Indra&nbsp;Prahastha,&nbsp;SH</h3>
                                                <p>Putra Pertama dari Bapak Drs H. Moch.Endang Djunaeni, MM<br/>& Ibu Dra Hj Siti Kuntari Broto</p>
                                                <div className="social">
                                                    <ul>
                                                        {/* <li><Link to="/" style={{color: '#044a47', fontSize: '20px'}}><i className="ti-facebook"></i></Link></li>
                                                        <li><Link to="/" style={{color: '#044a47', fontSize: '20px'}}><i className="ti-twitter-alt"></i></Link></li> */}
                                                        <li>
                                                            <a 
                                                                href="https://www.instagram.com/indraprahastha" 
                                                                target="_blank" 
                                                                style={{color: '#044a47', fontSize: '20px'}} rel="noreferrer">
                                                                    <i className="ti-instagram"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        );
    }
}
 
export default CoupleSection2;