import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import Button from "@material-ui/core/Button";
import '../index.css'

const ViewArticle = (props) => {
    return (
        <>
            <p 
                style={{ 
                    marginBottom: '0px', 
                    textAlign: 'right', 
                    fontSize: '.8rem', 
                    fontWeight: '500' }}>
                Contoh Postingan
            </p>
            <div style={{ border: 'solid 1px grey', padding: '10px', borderRadius: '10px' }}>
                <div style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                    <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Judul Postingan</p>
                    <h2 style={{ marginBottom: '5px' }}>{props.postTitle}</h2>
                </div>
                <div style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                    <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Cover Postingan</p>
                    {
                        props.postCover !== '' ?
                        <img src={props.postCover} alt="..." style={{}} /> :
                        null
                    }
                </div>
                <div>
                    {
                        props.content.map((dataContent, index) => {
                            return dataContent.class === 'format-standart-content' ?
                            <div key={index} style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                                <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Konten {index}</p>
                                <p>{dataContent.content}</p>
                            </div> :
                            dataContent.class === 'format-quote' ?
                            <div key={index} style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                                <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Konten {index}</p>
                                <blockquote style={{ 
                                    marginTop: '50px', 
                                    marginBottom: '10px', 
                                    padding: '10px', 
                                    paddingTop: '30px', 
                                    fontSize: '16px',
                                    background: '#2e4d76',
                                    color: 'white',
                                    borderRadius: '10px',
                                }}>{dataContent.content}</blockquote>
                            </div> :
                            <div key={index}>
                                {
                                    dataContent.content.length > 1 ?
                                    <div style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                                        <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Konten {index}</p>
                                        <Row>
                                            {
                                                dataContent.content.map((dataImage, indexImage) => {
                                                    return <Col
                                                        xs="6"
                                                        sm="6"
                                                        md="6"
                                                        lg="6"
                                                        xl="6"
                                                        key={"0"+indexImage}
                                                        style={{
                                                            textAlign: dataImage.align, 
                                                            paddingBottom: '20px', 
                                                            paddingTop: '20px', }}>
                                                        <img src={dataImage.base64} alt="..." style={{}} />
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </div> : dataContent.content.length !== 0 ?
                                    <div style={{ borderBottom: 'solid 1px lightgrey', marginBottom: '10px' }}>
                                        <p style={{ marginBottom: '0px', textAlign: 'left', fontSize: '.8rem', fontWeight: '500', color: 'lightgrey' }}>Konten {index}</p>
                                        <img src={dataContent.content[0]?.base64 ? dataContent.content[0]?.base64 : ''} alt="..." style={{}} />
                                    </div> : 
                                    null
                                }
                            </div>
                        })
                    }
                </div>
                <Button className="collapseBtn" style={{ background: '#a3888c', color: 'white', marginBottom: '10px' }} fullWidth onClick={() => props.saveDraft()}>
                    Simpan Draft
                </Button>
                <Button className="collapseBtn" style={{ background: '#a3888c', color: 'white' }} fullWidth onClick={() => props.saveToServer()}>
                    Simpan Ke Server
                </Button>
            </div>
        </>
    )
}

export default ViewArticle;