import React, { Component } from 'react'
import { toast } from "react-toastify";
import SectionTitle from '../../components/SectionTitle'

import vec1 from '../../images/contact/1.png'
import vec2 from '../../images/contact/2.png'
import { connect } from "react-redux";
import {
    postDataComment,
    postDataCopyDonasi,
} from "../../store/actions/action";

class RSVP extends Component {


    state = {
        name: '',
        email: '',
        comment: '',
        meal: '',
        service: '',
        guest: '',
        error: {}
    }


    changeHandler = (e) => {
        // console.log(e.target.value)
        // console.log(e.target.name)
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            comment,
            service,
            meal,
            guest, error } = this.state;

        // if (name === '') {
        //     error.name = "Please enter your name";
        // }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (comment === '') {
            error.comment = "Please enter your comment";
        }
        if (service === '' || service === 'Konfirmasi Kehadiran') {
            error.service = "Please Select your service";
        }
        // if (guest === '') {
        //     error.guest = "Please Select your Guest List";
        // }
        // if (meal === '') {
        //     error.meal = "Select Select Your Meal";
        // }


        // if (error) {
        //     this.setState({
        //         error
        //     })
        // }
        // if (error.name === '' && error.email === '' && error.email === '' && error.service === '' && error.comment === '' && error.meal === '' && error.guest === '') {
            // this.setState({
            //     name: '',
            //     email: '',
            //     comment: '',
            //     meal: '',
            //     guest: '',
            //     error: {}
            // })
        // }

        let objectSendToBE = {
            "user_teman_hidup_id": this.props.dataUser.user_teman_hidup_id,
            "presence_status": service === "Hadir" ? 1 : 0,
            "comment": comment,
            "user_email": email
        }

        if (service !== "" && comment !== "" && email !== "") {
            this.props.postDataComment(objectSendToBE)
            this.setState({
                name: '',
                email: '',
                comment: '',
                service: '',
                meal: '',
                guest: '',
                error: {}
            })
        } else {
            toast.error('Data not send because ...');
        }
    }

    render(){
        const { name,
            email,
            comment,
            service,
            guest,
            meal,
            error } = this.state;

        return(
            <section className={`wpo-contact-section ${this.props.pt}`} id="RSVP">
                <div className="container">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <SectionTitle MainTitle={'Komentar dan Konfirmasi'}/>
                            <form onSubmit={this.subimtHandler} className="form">
                                <div className="row">
                                    <div>
                                        <div className="form-field">
                                            <input value={this.props.name ? this.props.name.toUpperCase() : ''} onChange={this.changeHandler} className="form-control" type="text" name="name" placeholder="Name" disabled/>
                                            <p>{error.name ? error.name : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-field">
                                            <input onChange={this.changeHandler} value={email} type="email" className="form-control" name="email" placeholder="Email"/>
                                            <p>{error.email ? error.email : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-field">
                                            <textarea onChange={this.changeHandler} value={comment} type="text" className="form-control" name="comment" placeholder="comment" cols="40" rows="5"/>
                                            <p>{error.comment ? error.comment : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <select name="service" className="form-control" value={service} onChange={this.changeHandler}>
                                            <option>Konfirmasi Kehadiran</option>
                                            <option>Hadir</option>
                                            <option>Tidak Hadir</option>
                                        </select>
                                        <p>{error.service ? error.service : ''}</p>
                                    </div>
                                    {/* <div>
                                        <select name="guest" className="form-control" value={guest} onChange={this.changeHandler}>
                                            <option>Number Of Guests</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                        </select>
                                        <p>{error.guest ? error.guest : ''}</p>
                                    </div> */}
                                    {/* <div>
                                        <select name="meal" className="form-control last" value={meal} onChange={this.changeHandler}>
                                            <option>Meal Preferences</option>
                                            <option>Chicken Soup</option>
                                            <option>Motton Kabab</option>
                                            <option>Chicken BBQ</option>
                                            <option>Mix Salad</option>
                                            <option>Beef Ribs </option>
                                        </select>
                                        <p>{error.meal ? error.meal : ''}</p>
                                    </div> */}
                                    <div className="submit-area">
                                        <div className="form-submit">
                                            <button type="submit" className="theme-btn-s3">Kirim Komentar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="border-style"></div>
                        </div>
                        <div className="vector-1">
                            <img src={vec1} alt=""/>
                        </div>
                        <div className="vector-2">
                            <img src={vec2} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      dataUserPath: state.wishList.dataUserPath,
    };
};

export default connect(mapStateToProps, {
    postDataComment,
    postDataCopyDonasi,
})(RSVP);